import revive_payload_client_EVFOuCuYyV from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1iSVKV2FYZ from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_41s937REjF from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_d5cTEZuBBE from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nOUA1CbQsW from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_imyQiIUr39 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dL9x0xn45h from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_1f520C2XCH from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.10_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_xT7PiHEfHN from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_oejmbtiyggw7j7wnhcbgkjwqju/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/pwa-icons-plugin.ts";
import pwa_client_9Xy60DLe71 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@2.79.2_vite@5.4_cumtqqiwgshcakvbvhupsolkpq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/qa/argocd-releases/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_8fMk05vpWN from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_EVFOuCuYyV,
  unhead_1iSVKV2FYZ,
  router_41s937REjF,
  payload_client_d5cTEZuBBE,
  navigation_repaint_client_nOUA1CbQsW,
  check_outdated_build_client_imyQiIUr39,
  chunk_reload_client_dL9x0xn45h,
  plugin_vue3_1f520C2XCH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xT7PiHEfHN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_9Xy60DLe71,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  formkitPlugin_pZqjah0RUG,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_8fMk05vpWN,
  plugin_PXQeuxRKoc
]