import type { Middleware } from 'openapi-fetch'
import createClient from 'openapi-fetch'
import { AppConfigKeys } from './enums'
import type { paths } from './paapi'

export * from './enums'
export * from './exclusion'
export * from './paapi-endpoints'
export * from './policy'
export * from './claimlab'
export * from './validation'

export type PaAPIClientMiddleware = Middleware

export const AUTH_PROVIDER_HEADER = 'auth-provider'
export const WORKSPACE_ID_HEADER = 'x-rialtic-workspace-id'

type HeadersArg =
  | Headers
  | (Record<string, string> & {
      Authorization: string
      [AUTH_PROVIDER_HEADER]: string
      [WORKSPACE_ID_HEADER]?: string
    })

const forwardedHeaders: Record<string, boolean> = {
  accept: true,
  authorization: true,
  [AUTH_PROVIDER_HEADER]: true,
  [WORKSPACE_ID_HEADER]: true,
  'cache-control': true,
  'content-type': true,
  cookie: true,
  'if-none-match': true,
  'if-modified-since': true,
  origin: true,
  'x-datadog-trace-id': true,
  'x-datadog-parent-id': true,
  'x-datadog-origin': true,
  'x-datadog-sampling-priority': true,
}

const mergeAndFilterHeaders = (req: Request, headers: HeadersArg): Request => {
  if (headers instanceof Headers) {
    headers.forEach((value, key) => {
      if (forwardedHeaders[key.toLowerCase()]) req.headers.set(key, value)
    })

    return req
  }

  for (const [key, value] of Object.entries(headers)) {
    if (forwardedHeaders[key.toLowerCase()]) req.headers.set(key, value)
  }

  return req
}

export const createPaAPIClient = (baseUrl: string, headers: HeadersArg) => {
  const middleware: Middleware = {
    async onRequest(req) {
      return mergeAndFilterHeaders(req, headers)
    },
  }
  const client = createClient<paths>({ baseUrl })

  client.use(middleware)

  return client
}

export type KvKeys = {
  name: string
}

export const parseAppConfig = (keys: KvKeys[]) => {
  return keys.reduce(
    (state, { name }) => {
      state[name.replace('app:config:', '') as AppConfigKeys] = true
      return state
    },
    {
      [AppConfigKeys.maintenance]: false,
    } as Record<AppConfigKeys, boolean>,
  )
}

export const featureFlags = {
  [AppConfigKeys.featureClaimLab]: false,
  [AppConfigKeys.featureClaimTimestamp]: false,
  [AppConfigKeys.featureAccountApiClients]: false,
  [AppConfigKeys.featureClaimIDRedirect]: false,
  [AppConfigKeys.featureClientSSO]: false,
  [AppConfigKeys.featureChatRip]: false,
  [AppConfigKeys.featureClaimFiltering]: false,
  [AppConfigKeys.featureHiroTimestamps]: false,
  [AppConfigKeys.featurePeriodicRefresh]: false,
  [AppConfigKeys.featurePolicyMetadata]: false,
  [AppConfigKeys.featureZendeskLink]: false,
  [AppConfigKeys.featurePolicySync]: false,
  [AppConfigKeys.maintenance]: false,
}

type UnleashStrategy = {
  name: string
  // parameters: Object //TODO: needs more detail here or get types from a lib, irrelevant for Gitlab's version
}

export type UnleashFeatureFlag = {
  description: string
  enabled: boolean
  name: string
  strategies: Array<UnleashStrategy>
}
