import type { UnleashFeatureFlag } from '@rialtic/types'
import type { PaAPI } from '@rialtic/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAppConfigurationUnleashed = defineStore(
  'app-config-unleashed',
  {
    state: () => ({
      featureFlags: [] as UnleashFeatureFlag[],
    }),

    actions: {
      async getAll() {
        const { $datadog } = useNuxtApp()
        try {
          const apiFetch = $apiFetcher()
          const data = await apiFetch<PaAPI.Get['/features']>(
            '/api/paapi/features',
          )
          this.featureFlags = data as UnleashFeatureFlag[]
        } catch (error) {
          $datadog.addError(error, {
            function: 'stores/appConfig:getAllUnleash',
          })
        }

        return this.$state
      },
    },

    getters: {
      isUnleashFeatureEnabled: (state) => {
        return (featureName: string): boolean => {
          return state.featureFlags?.length
            ? state.featureFlags.some(
                (feature: UnleashFeatureFlag) =>
                  feature.name === featureName && feature.enabled,
              )
            : false
        }
      },
    },
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useAppConfigurationUnleashed, import.meta.hot),
  )
